.mantine-DatePicker-day {
    font-size: 13px;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 16px;
}
.mantine-DatePicker-day[data-selected="true"] {
    font-weight: bold;
    background-color: #016DA9;
    color: white;
    /* color: black; */
}
.mantine-DatePicker-day[data-disabled] {
    cursor: default;
}
.mantine-DatePicker-day[data-selected="true"]:hover, :where([data-mantine-color-scheme='dark']) .mantine-DatePicker-day[data-selected="true"] {
    color: white;
}
/* .return-calendar .mantine-DatePicker-day[data-selected="true"] {
    background-color: #0CA678;
} 
.btn-destination[data-variant="filled"] {
    color: black;
}*/
:where([data-mantine-color-scheme='light']) .btn-destination[data-variant="filled"] {
    color: white;
    background-color: var(--mantine-color-primary-9);
    font-weight: 900;
    font-style: normal;
}
:where([data-mantine-color-scheme='light']) .btn-destination[data-variant="filled"]:hover, .btn-destination[data-variant="subtle"]:hover {
    background-color: var(--mantine-color-primary-6);
    color: var(--mantine-color-primary-9);
}
:where([data-mantine-color-scheme='dark']) .btn-destination[data-disabled], :where([data-mantine-color-scheme='light']) .btn-destination[data-disabled], .btn-destination[data-disabled]:hover {
    background-color: transparent;
    color: var(--mantine-color-gray-4);
    cursor: default;
}
:where([data-mantine-color-scheme='dark']) .btn-destination:not([data-disabled])[data-variant="subtle"] {
    color: white;
}
:where([data-mantine-color-scheme='dark']) .btn-destination:not([data-disabled])[data-variant="filled"] {
    font-weight: 900;
    background-color: transparent;
    color: white;
}
.btn-slide-thumb {
    background-color: #4BA5FD;
    border-radius: 8px;
    border: 0;
    color: white;
    padding: 6px;
    font-size: medium;
    font-weight: 600;
}
.btn-slide-caret {
    background-color: white !important;
    border-radius: 8px;
}
.btn-slide-overlay {
    border-radius: 8px;
    background-color: var(--mantine-color-gray-3) !important;
    color: var(--mantine-color-dark-8) !important;
}
.btn-slide-success {
    border-radius: 8px;
    background-color: var(--mantine-color-green-6) !important;
}
.table-schedule {
    table-layout: fixed;
}
.table-schedule thead {
    border-bottom: 2px solid var(--mantine-color-gray-4);
}
.table-schedule tbody td, .table-schedule thead th {
    text-align: center;
    padding-inline: 0;
}
.table-schedule tbody td {
    padding-top: 4px;
    padding-bottom: 4px;
}

.table-schedule tbody tr:last-child td:first-child {
    border-bottom-left-radius: var(--mantine-radius-default);
}
.table-checkout {
    table-layout: fixed;   
}
.btn-checkout {
    background-color: #016DA9;
    color: white;
}

.btn-checkout:hover {
    background-color: #015787;
    color: white;
}
.btn-primary:hover {
    background-color: #d0ebff;
    color: #016DA9;
}